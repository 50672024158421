// source: system/application/views/widgets/moreCategories.php
/**
 * Script for secondary category slider at secondaryCategory.php widget.
 */
$(function () {
    // Variables declaration for use in secondary category slider
    var wrapper = document.querySelector('.cards-wrapper');
    var allCards = document.querySelectorAll('.cards-wrapper .card');
    var lastPixels = 0;
    var visibleCardCount = 4;
    var extraSpaceCount = 5;
    var displayAreaForLastMinus = 5;
    var clickedDotNum = 1;
    var pixels = 0;

    // Catch the next & previous button
    var next = document.querySelector('.slider-action-buttons .next');
    var previous = document.querySelector('.slider-action-buttons .previous');

    // Hide the previous button by default
    if (typeof previous !== "undefined" && clickedDotNum == 1) {
        previous.classList.add("hidden-element");
    }

    // Hide the next button on condition
    if (allCards.length < visibleCardCount || clickedDotNum == (allCards.length - visibleCardCount)) {
        next.classList.add("hidden-element");
    }

    // On click previous button event handler
    if (typeof previous !== "undefined") {
        previous.addEventListener('click', function (e) {
            var displayArea = allCards[0].clientWidth + extraSpaceCount;
            if (clickedDotNum === 0) {
                return;
            }
            clickedDotNum--;
            var pixels = -displayArea * clickedDotNum;
            wrapper.style.transform = 'translateX(' + pixels + 'px)';
            if (clickedDotNum === 0) {
                previous.classList.add("hidden-element");
                clickedDotNum++;
            }
            next.classList.remove("hidden-element");
        });
    }

    // On click next button event handler
    next.addEventListener('click', function (e) {
        if (wrapper.clientWidth < 570) {
            visibleCardCount = 3;
            displayAreaForLastMinus = 4;
        }
        if (wrapper.clientWidth < 500) {
            visibleCardCount = 2;
            displayAreaForLastMinus = 3;
        }

        // To hide the extra space  
        var displayArea = allCards[0].clientWidth + extraSpaceCount;
        if (clickedDotNum === (allCards.length - visibleCardCount)) {
            displayArea = allCards[0].clientWidth + extraSpaceCount;
            var displayAreaForLast = (displayArea * displayAreaForLastMinus) - wrapper.clientWidth;
            next.classList.add("hidden-element");
            pixels = (lastPixels - displayAreaForLast);
            wrapper.style.transform = 'translateX(' + pixels + 'px)';
            return;
        } else {
            previous.classList.remove("hidden-element");
            pixels = (-displayArea * clickedDotNum);
            wrapper.style.transform = 'translateX(' + pixels + 'px)';
            lastPixels = pixels;
            clickedDotNum++;
        }
    });
});