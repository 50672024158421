/**
 * Start: displayFeedbackPanel
 *
 * Generic method to display the feedback panel
 *
 * @param object panelSettings  - The settings for the panel
 * @param object panelContainer - The container that holds the html
 * @param string string type    - The type of panel to display: error, info, success
 * @param string string message - The message to display
 *
 * @return void
 */
function displayFeedbackPanel(panelSettings, panelContainer, type, message) {

    // default panel settings
    var defaultPanelSettings = {
        default: {panelClass: 'panel-default', panelHeading: "See below message."},
        error: {panelClass: 'panel-danger', panelHeading: "Error!"},
        info: {panelClass: 'panel-info', panelHeading: "Info!"},
        success: {panelClass: 'panel-success', panelHeading: "Success!"},
    };

    // if a custom settings are not given, use the default settings
    if (!panelSettings) {
        panelSettings = defaultPanelSettings;
    }

    // remove existing panel classes, in order to display the new one
    for (var i in panelSettings) {
        if (panelSettings.hasOwnProperty(i)) {
            panelContainer.removeClass(panelSettings[i].panelClass);
        }
    }

    var panelTypeClass = panelSettings[type] ? panelSettings[type].panelClass : panelSettings['default'].panelClass;
    var panelTypeHeading = panelSettings[type] ? panelSettings[type].panelHeading : panelSettings['default'].panelHeading;

    panelContainer.addClass(panelTypeClass);
    panelContainer.find(".panel-heading").html(panelTypeHeading);
    panelContainer.find('.panel-body').html(message);
    panelContainer.fadeIn();
}

/**
 * End: displayFeedbackPanel
 */


/**
 * Start: hideFeedbackPanel
 *
 * Hide the feedback panel
 *
 * @param object panelContainer - The container that holds the html
 *
 * @return void
 */
function hideFeedbackPanel(panelContainer) {
    panelContainer.hide();
    panelContainer.find(".panel-heading").html("");
    panelContainer.find('.panel-body').html("");
}
/**
 * End: hideFeedbackPanel
 */
