// source: system/application/views/survey/survey.php

$(function () {
    if (WebPageObj.survey && WebPageObj.surveyRefresh) {
        $(function () {
            (function () {
                setTimeout(function () {
                    window.location = window.location.origin;
                }, 3000);
            })();
        });
    }
});