// source: system/application/views/search/search_show.php

$(function () {
    if (WebPageObj.search) {
        if (window.location.hash != '') {
            q = window.location.hash.substring(1);
            q = q.split('/');
            if (q[0] && q[0] == '!' && q[1] && q[1] == 'show' && q[2] && /[0-9]{2,}/.test(q[2])) {
                $('#show-more-products').parent().parent().find('.show-more-link').hide();
                $('#show-more-products').parent().parent().find('.show-more-loader').show();
                $('#product-load-holder').load('/search/reload/20/' + q[2], function () {
                    $('.show-more-box').replaceWith($('#product-load-holder').html());
                    $('#product-load-holder').html('');
                    $("html, body").animate({scrollTop: $('#prod-' + q[2]).offset().top + 'px'}, 0);
                });

            }
        }

        $(document).on('click', '#show-more-products', function (e) {
            $(this).parent().parent().find('.show-more-link').hide();
            $(this).parent().parent().find('.show-more-loader').show();
            $params = $(this).attr('data-cat').replace('subcat-', '');
            $params = $params.split('-');
            $('#product-load-holder').load('/search/reload/' + $params[1], function () {
                $('.show-more-box').replaceWith($('#product-load-holder').html());
                $('#product-load-holder').html('');
                window.location.hash = '!/show/' + $params[1];
            });

            e.preventDefault();
            return false;
        });

        $('.sympathy-item.active').on('click', function (e) {
            $('#sympathy-items').find('.sympathy-item').each(function () {
                if (!$(this).hasClass('active')) {
                    $(this).toggle();
                }
            });
            e.preventDefault();
            return false;
        });


        $('#refineform').find('input').on('change', function () {
            $params = $('#refineform').attr('class').replace('subcat-', '');
            $('#featproduct-listing').html('').append($('#ajax-loader-banner-big').html());
            $('#featproduct-listing').find('.ajax-loader-banner-big').show();
            $.ajax({
                type: "POST",
                url: '/search/reload/0',
                data: $('#refineform').serialize()
            }).done(function (response) {
                $('#featproduct-listing').html(response);
            });
        });

        $('#refine-delivery-date').on('change', function () {
            $params = $('#refineform').attr('class').replace('subcat-', '');
            $params = $params.split('-');
            $('#featproduct-listing').html('').append($('#ajax-loader-banner-big').html());
            $('#featproduct-listing').find('.ajax-loader-banner-big').show();
            $('#refineform').append('<input id="date-changed" type="hidden" name="date_changed" value="1" />');
            $.ajax({
                type: "POST",
                url: '/search/reload/0',
                data: $('#refineform').serialize()
            }).done(function (response) {
                $('#featproduct-listing').html(response);
                $('#refineform').find('#date-changed').remove();
            }).fail(function (xhr, ajaxOptions, thrownError) {
                $('#refineform').find('#date-changed').remove();
            });
        });
    }
});