/**
 * Web page object containing eg boolean of the pages url
 * 
 * @type object
 */
var WebPageObj = {
    account: (window.location.href.indexOf("account") > -1),
    addons: (window.location.href.indexOf("make-it-festive") > -1),
    categories: (window.location.href.indexOf("categories") > -1),
    checkout: (window.location.href.indexOf("checkout") > -1),
    thankYou: (window.location.href.indexOf("thank-you") > -1),
    orderModification: (window.location.href.indexOf("/m/") > -1),
    accountRegister: (window.location.href.indexOf("account/register") > -1),
    accountCreatedThanks: (window.location.href.indexOf("account/thanks") > -1),
    accountAddressBook: (window.location.href.indexOf("account/addressbook") > -1),
    accountOrders: (window.location.href.indexOf("account/orders") > -1),
    accountCalendar: (window.location.href.indexOf("account/calendar") > -1),
    accountRewardPoints: (window.location.href.indexOf("account/rewardpoints") > -1),
    accountSettings: (window.location.href.indexOf("account/settings") > -1),
    product: (window.location.href.indexOf("product") > -1),
    search: (window.location.href.indexOf("search") > -1),
    survey: (window.location.href.indexOf("feedback/survey") > -1),
    feedback: (window.location.href.indexOf("feedback/rateexperience") > -1),
    customerPrivacyRequestVerification: (window.location.href.indexOf("customer_privacy/request_data") > -1),
    customerPrivacyRequestConfirmation: (window.location.href.indexOf("customer_privacy/confirm_request") > -1),
    customerPrivacyOptInOptOut: (window.location.href.indexOf("customer_privacy/optin_optout") > -1),
    customerReview:(window.location.href.indexOf("customer-reviews") > -1),
    surveyRefresh: 0,
    currentCattegoryId: 0,
    rootCattegoryId: 0,
    currentStep: '',
    isMobile: 0
};

/**
 * Global object variable
 *
 * @type {{zip: null, city: null, latitude: null, page: null, state: null, longitude: null}}
 */
var GoogleMapsApi = {
    page: null,
    state: null,
    zip: null,
    city: null,
    latitude: null,
    longitude: null
};

/**
 * Global variable for handling footer links
 *
 * @type {{links: null, sections: null}}
 */
var FooterLinks = {
    sections: null,
    links: null
};

/**
 * Object to hold site wide variables.
 *
 * Add all miscellaneous variables here that need to be accessed globally.
 *
 * @type {{avasPhoneNumber: string}}
 */
var GlobalSiteVars = {
  avasPhoneNumber: '877-638-3303',
  customerServiceNumber: '855-607-7710'
};