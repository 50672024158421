$(function () {
    'use strict';
    // Breakpoint where we destroy swiper for Testimonials swiper on mobile (case 1189)
    var swiperBreakpointTestimonials = window.matchMedia('(min-width: 667px)');
    // Keep track of swiper instances
    var myTestimonialsSwiper = void 0;

    var swiperBreakpointTestimonialsChecker = function swiperBreakpointTestimonialsChecker() {
        // if larger viewport and multi-row layout needed
        if (swiperBreakpointTestimonials.matches === true) {
            // clean up old instances and inline styles when available
            if (myTestimonialsSwiper !== undefined) {
                swiperDestroyTestimonials();
                myTestimonialsSwiper.destroy(true, true);
                return;
            }
            // else if a small viewport and single column layout needed
        } else if (swiperBreakpointTestimonials.matches === false) {
            // fire small viewport version of swiper
            return swiperEnableTestimonials();
        }
    };
    var swiperDestroyTestimonials = function swiperDestroyTestimonials() {
        if ($('.swiper-testimonials').hasClass('swiper-container')) {
            $('.swiper-testimonials').removeClass('swiper-container');
        }
        if ($('.sw-wrapper').hasClass('swiper-wrapper')) {
            $('.sw-wrapper').removeClass('swiper-wrapper');
        }
        if ($('.sw-slide').hasClass('swiper-slide')) {
            $('.sw-slide').removeClass('swiper-slide');
        }
    };

    var swiperInitializeTestimonials = function swiperInitializeTestimonials() {
        if (!$('.swiper-testimonials').hasClass('swiper-container')) {
            $('.swiper-testimonials').addClass('swiper-container');
        }
        if (!$('.sw-wrapper').hasClass('swiper-wrapper')) {
            $('.sw-wrapper').addClass('swiper-wrapper');
        }
        if (!$('.sw-slide').hasClass('swiper-slide')) {
            $('.sw-slide').addClass('swiper-slide');
        }
    };

    var swiperEnableTestimonials = function swiperEnableTestimonials() {
        if (!$('.swiper-testimonials').length) {
            return;
        }
        swiperInitializeTestimonials();
        myTestimonialsSwiper = new Swiper('.swiper-testimonials', {
            loop: true,
            slidesPerView: 2,
            spaceBetween: 20,
            centeredSlides: true,
            a11y: true,
            keyboardControl: true,
            grabCursor: true,
            // Breakpoints
            breakpoints: {
                460: {
                    slidesPerView: 1,
                    spaceBetween: 10
                }
            },
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        });
    };
    // keep an eye on viewport size changes
    swiperBreakpointTestimonials.addListener(swiperBreakpointTestimonialsChecker);
    // start Testimonials Swipper
    swiperBreakpointTestimonialsChecker();
});
