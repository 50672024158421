$(function () {
    $('#show-map').on('click', function (e) {
        if ($('#gmap_img').attr('data-loaded') == 'false') {
            var url = '/landing/getstaticmap/' + GoogleMapsApi.latitude + '/' + GoogleMapsApi.longitude;
            var zoom = $('#gmap_img').attr('data-zoom');
            var width = $('#gmap_img').attr('width');
            var height = $('#gmap_img').attr('height');
            if (zoom && width && height) {
                url += '/' + zoom + '/' + width + '/' + height;
            }
            $.ajax({
                url: url,
                dataType: "json"
            }).done(function (data) {
                $('#gmap_img').attr('src', data.link);
                $('#gmap_img').attr('data-loaded', 'true');
                $('.gmap').slideToggle('fast');
            });
        } else {
            $('.gmap').slideToggle('fast');
        }

        e.preventDefault();
        return false;
    });
});